<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon :loading="loading" @click="print" v-bind="attrs" v-on="on">
        <v-icon>fas fa-print</v-icon>
      </v-btn>
    </template>
    <span>Print</span>
  </v-tooltip>
</template>

<script>
import print from "print-js";
import html2canvas from "html2canvas";
export default {
  name: "print_button",
  data: () => ({
    loading: false
  }),
  methods: {
    async print() {
      this.$emit("click");
      this.loading = true;
      let x = window.scrollX,
        y = window.scrollY;
      window.scrollTo(0, 0);
      let canvas;
      if (this.html_element) {
        canvas = await html2canvas(this.html_element);
      } else if (this.custom_id) {
        canvas = await html2canvas(document.getElementById(this.custom_id));
      } else {
        return;
      }
      const dataUrl = canvas.toDataURL("image/png", 1.0);
      print(dataUrl, "image");
      window.scrollTo(x, y);
      this.loading = false;
    }
    // async printHtml() {
    //   let canvas = await html2canvas(this.html_element);
    //   const dataUrl = canvas.toDataURL("image/png", 1.0);
    //   //attempt to save base64 string to server using this var
    //   var windowContent = "<!DOCTYPE html>";
    //   windowContent += "<html>";
    //   windowContent += "<head><title>Print Report</title></head>";
    //   windowContent += "<body>";
    //   windowContent += '<img src="' + dataUrl + '">';
    //   windowContent += "</body>";
    //   windowContent += "</html>";
    //   var printWin = window.open(
    //     "",
    //     "",
    //     "width=" + screen.availWidth + "height=" + screen.availHeight
    //   );
    //   printWin.document.open();
    //   printWin.document.write(windowContent);
    //   printWin.document.addEventListener(
    //     "load",
    //     function() {
    //       printWin.focus();
    //       printWin.print();
    //       printWin.document.close();
    //       printWin.close();
    //     },
    //     true
    //   );
    //   printWin.focus();
    //   printWin.print();
    //   printWin.close();
    // }
  },
  props: {
    html_element: {},
    custom_id: {
      type: String,
      default: null
    }
  }
};
</script>
